<script lang="ts">
  import Button from "../common/Button.svelte";
  import { trialRequestStore } from "../stores";
  interface Props extends Button {
    children?: import("svelte").Snippet;
  }

  let { children, ...props }: Props = $props();
</script>

<Button
  {...props}
  onclick={() => {
    $trialRequestStore = {
      modalOpen: true,
      submitted: false,
    };
  }}
>
  {#if children}
    {@render children()}
  {:else}
    Trials
  {/if}
</Button>
